<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="!ready" />
        <div v-if="ready">
          <crud-list
            v-model="gestores"
            :headers="headers"
            :slots="['item.telefones']"
            :actions="actions"
            :show-expand="true"
            :permission="'list_gestor'"
            :sort-by="['nome']"
            :sort-desc="[false]"
            :export-pdf-xlsx="true"
          >
            <template v-slot:expanded-item="{ item }">
              <td
                v-if="item.unidades.length"
                :colspan="headers.length"
              >
                <v-card-title>
                  <span style="font-size: 14px; color: rgba(0, 0, 0, 0.87); font-weight: bold"> Unidades de negócio</span>
                </v-card-title>
                <v-list-item
                  v-for="uni in item.unidades"
                  :key="uni.nome_fantasia"
                  style="min-height: 20px; max-height: 52px;  padding: 0px;  padding-top: 0px;"
                >
                  <v-list-item-content>
                    <v-card
                      elevation="4"
                      outlined
                      class="my-0 py-0"
                    >
                      <v-row class="ml-2 my-0 py-0">
                        <v-card
                          elevation="0"
                          class="my-0 py-0"
                        >
                          <v-card-text class="mb-2 pl-2 pr-2 my-2 py-1">
                            <div>
                              <span style="font-size: 12px; color: rgba(0, 0, 0, 0.87); font-weight: bold">{{ `${uni.nome_fantasia}  ( ${uni.cnpj + ' - ' + uni.razao_social } )` }}</span>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-row>
                    </v-card>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content />
                </v-list-item>
              </td>
            </template>
            <template v-slot:btnCadastro>
              <v-col>
                <v-btn
                  class="text-none text-white"
                  block
                  rounded
                  color="primary"
                  title="Cadastrar novo gestor"
                  @click="$router.push({ path: 'gestores/cadastrar' })"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-file-plus
                  </v-icon>
                  Cadastrar
                </v-btn>
              </v-col>
            </template>
            <template v-slot:[`item.telefones`]="{ item }">
              <div
                v-for="(telefone, index) in item.telefones"
                :key="index"
              >
                <span v-if="telefone">
                  {{ `${index + 1}:  ${telefone}` }}
                  <br>
                </span>
              </div>
            </template>
          </crud-list>
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import GestoresStore, { BOOTSTRAP, DELETE_GESTOR } from '@/store/modules/gestores'
  import { mapState, mapActions } from 'vuex'
  import { sortDateTime } from '../../utils/sorts'

  export default {
    data () {
      return {
        search: '',
        actions: [
          {
            title: 'Editar gestor',
            color: 'info darken-3',
            click: item => this.editarGestor(item),
            icon: 'mdi-pencil',
          },
          {
            title: 'Excluir Gestor',
            color: 'red darken-3',
            click: item => this.DELETE_GESTOR(item),
            icon: 'mdi-trash-can-outline',
          },
        ],
        headers: [
          { text: '', value: 'data-table-expand', sortable: false, groupable: false, width: '10px' },
          { text: '', value: 'actions', sortable: false, groupable: false, width: '40px' },
          { align: 'left', sortable: true, groupable: false, text: 'CPF', value: 'cpf', width: 'auto', floatingfilter: true, fixed: true, report: true },
          { align: 'left', sortable: true, groupable: false, text: 'Nome', value: 'nome', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: false, groupable: false, text: 'Telefones', value: 'telefones', width: 'auto', report: true },
          { align: 'left', sortable: true, groupable: false, text: 'RG', value: 'rg', width: 'auto' },
          { align: 'left', sortable: true, groupable: true, text: 'Papel', value: 'roles.description', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, text: 'Email', value: 'email', width: 'auto', floatingfilter: true, report: true },
          { align: 'center', sortable: true, groupable: false, text: 'Data de Nascimento', value: 'data_nascimento', width: 'auto', sort: sortDateTime },
          { align: 'left', sortable: true, groupable: true, text: 'Sexo', value: 'sexo', width: 'auto' },
        ],
      }
    },
    computed: {
      ...mapState('gestores', ['ready', 'gestores']),
    },
    async created () {
      if (!this.$store.hasModule('gestores')) { this.$store.registerModule('gestores', GestoresStore) }
      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('gestores', [BOOTSTRAP, DELETE_GESTOR]),
      editarGestor ({ id }) {
        this.$router.push({
          path: `/${this.$user.get().role}/gestores/alterar`,
          query: { id },
        })
      },
    },
  }
</script>
